<template>
  <div>
    <h1>Reminders</h1>
    <div>
      <hr>
      <div class="row mb-1">
        <div class="col-9"></div>
        <div class="col-3">
          <b-form-group>
            <div class="d-flex align-items-right">
              <b-form-input
                v-model="searchFilterTerm"
                placeholder="Filter Claims"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: false,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        @on-sort-change="onSortChange"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: closedDate -->
          <span
            v-if="props.column.field === 'closedDate'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.closedDate | dateFormat }}</span>
          </span>

          <!-- Column: remindOnDate -->
          <span
            v-else-if="props.column.field === 'remindOnDate'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.remindOnDate | dateFormat }}</span>
          </span>

          <!-- Column: Subject -->
          <span v-else-if="props.column.field === 'subjectName'">
            <router-link
              :to="'subject/' + props.row.subjectId"
            >{{ props.row.subjectName }}</router-link>
          </span>

          <!-- Column: InternalNumber -->
          <span v-else-if="props.column.field === 'internalNumber'">
            <router-link
              :to="'cases/' + props.row.caseId + '/detail'"
            >{{ props.row.internalNumber }}</router-link>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'Action'">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="deleteReminder(props.row.reopenReminderId)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>

        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing {{ displayStartIndex }} - {{ displayEndIndex }} of
                {{ totalResultCount }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['100', '150', '200']"
                class="mx-1"
                @input="
                  (value) => {
                    props.perPageChanged({ currentPerPage: value });
                    onPageSizeChanged({ currentPerPage: value });
                  }
                "
              />
              <span class="text-nowrap">per page</span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :value="1"
                :total-rows="totalResultCount"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
                @page-click="onPageChanged"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
    <b-modal
      id="modal-delete-reminder"
      ok-variant="danger"
      ok-title="Delete"
      modal-class="modal-danger"
      centered
      title="Confirm Delete Reminder"
      @ok="confirmDeleteReminder"
    >
      <div>
        Are you sure you want to delete Reminder. This cannot be undone.
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import APIService from "@core/utils/APIService";
import {mapGetters, mapMutations} from "vuex";

const apiService = new APIService();

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  filters: {
    dateFormat(val) {
      if (val) {
        const value = val.substr(0, 10);
        const date = new Date(value);
        const options = {month: '2-digit', day: '2-digit', year: 'numeric'}
        return date.toLocaleDateString("en-US", options);
      }
    },
  },
  data() {
    return {
      pageLength: 100,
      currentPage: 1,
      displayStartIndex: 1,
      displayEndIndex: 100,
      dir: false,
      totalResultCount: 0,
      awaitingSearch: false,
      IncludeClosedCases: false,
      UserType: "",
      deleteReminderId: null,
      sortModel: {
        fieldName: "",
        sort: 1
      },
      columns: [
        {
          label: "Claim Number",
          field: "claimFileNo",
        },
        {
          label: "Internal Number",
          field: "internalNumber",
        },
        {
          label: "Account Manager",
          field: "accountManagerName",
        },
        {
          label: "Subject",
          field: "subjectName",
        },
        {
          label: "Location",
          field: "location",
        },
        {
          label: "Closed Date",
          field: "closedDate",
        },
        {
          label: "Reminder Date",
          field: "remindOnDate",
        },
        {
          label: "Reminder Text",
          field: "notes",
        },
        {
          label: "Client",
          field: "clientName",
        },
        {
          label: "Action",
          field: "Action",
        },
      ],
      rows: [],
      searchFilterTerm: "",
    };
  },
  computed: {
    ...mapGetters({
      getClaimsFilter: "search/getClaimsFilter"
    }),
  },
  watch: {
    searchFilterTerm: function (val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.loadCasesPage(1);
          this.awaitingSearch = false;
        }, 1500); // ms delay search debounce
      }
      this.setClaimsFilter(val)
      this.awaitingSearch = true;
    },
  },
  mounted() {
    this.searchFilterTerm = this.getClaimsFilter;
    this.UserType = localStorage.getItem("userType");
    this.loadCasesPage(1)
  },
  methods: {
    ...mapMutations({
      setClaimsFilter: "search/setClaimsFilter"
    }),

    loadCasesPage(pageNumber) {
      this.rows = [];
      var pageZeroIndex = pageNumber - 1;
      var startIndex = pageZeroIndex * this.pageLength;
      this.displayStartIndex = startIndex + 1;
      this.displayEndIndex = this.pageLength + this.displayStartIndex - 1;
      const postData = {
        "skip": startIndex,
        "take" : this.pageLength,
        "searchTerm" : this.searchFilterTerm,
        "sortModel" : this.sortModel
      }
      apiService
          .post("case-reopen-reminder/search", postData)
          .then((response) => {
            if (response && response.data) this.loadCaseResults(response.data);
          });
    },

    onSortChange(props) {
      const fieldName = props[0].field === "accountManagerName" ? "accountManagerId" :
          props[0].field === "clientName" ? "clientId" :
          props[0].field === "subjectName" ? "subjectId" : props[0].field;
      if (props[0].field === "location" || props[0].field === "notes" || props[0].field === "Action") {
        return
      }
      this.sortModel = {
        fieldName,
        "sort": props[0].type === "asc" ? 0 : 1
      }
      this.loadCasesPage(this.currentPage);
    },

    loadCaseResults(responseData) {
      if (responseData) {
        this.rows = responseData.items;
        this.totalResultCount = responseData.total;
        this.displayEndIndex = this.rows.length + this.displayStartIndex - 1;
      }
    },

    onPageChanged(bvEvent, page) {
      this.loadCasesPage(page);
    },

    onPageSizeChanged(newPageSize) {
      this.loadCasesPage(this.currentPage);
      this.pageLength = newPageSize.currentPerPage;
    },

    confirmDeleteReminder() {
      apiService
          .delete("case-reopen-reminder/" + this.deleteReminderId)
          .then(() => {
            this.deleteReminderId = null;
            this.loadCasesPage(this.currentPage);
          });
    },

    deleteReminder(reminderId) {
      this.deleteReminderId = reminderId;
      this.$bvModal.show("modal-delete-reminder");
    },

    showToast(variant, position, timeout, data) {
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "InfoIcon",
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          }
      );
    },
  },
};
</script>

<style>

</style>
