var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("Reminders")]),_c('div',[_c('hr'),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-9"}),_c('div',{staticClass:"col-3"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-right"},[_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Filter Claims","type":"text"},model:{value:(_vm.searchFilterTerm),callback:function ($$v) {_vm.searchFilterTerm=$$v},expression:"searchFilterTerm"}})],1)])],1)]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{
        enabled: false,
      },"pagination-options":{
        enabled: true,
        perPage: _vm.pageLength,
      }},on:{"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'closedDate')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("dateFormat")(props.row.closedDate)))])]):(props.column.field === 'remindOnDate')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("dateFormat")(props.row.remindOnDate)))])]):(props.column.field === 'subjectName')?_c('span',[_c('router-link',{attrs:{"to":'subject/' + props.row.subjectId}},[_vm._v(_vm._s(props.row.subjectName))])],1):(props.column.field === 'internalNumber')?_c('span',[_c('router-link',{attrs:{"to":'cases/' + props.row.caseId + '/detail'}},[_vm._v(_vm._s(props.row.internalNumber))])],1):(props.column.field === 'Action')?_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteReminder(props.row.reopenReminderId)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon"}}),_c('span',[_vm._v("Delete")])],1)],1)],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Showing "+_vm._s(_vm.displayStartIndex)+" - "+_vm._s(_vm.displayEndIndex)+" of "+_vm._s(_vm.totalResultCount)+" ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['100', '150', '200']},on:{"input":function (value) {
                  props.perPageChanged({ currentPerPage: value });
                  _vm.onPageSizeChanged({ currentPerPage: value });
                }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v("per page")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":_vm.totalResultCount,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); },"page-click":_vm.onPageChanged},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])]}}])})],1),_c('b-modal',{attrs:{"id":"modal-delete-reminder","ok-variant":"danger","ok-title":"Delete","modal-class":"modal-danger","centered":"","title":"Confirm Delete Reminder"},on:{"ok":_vm.confirmDeleteReminder}},[_c('div',[_vm._v(" Are you sure you want to delete Reminder. This cannot be undone. ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }